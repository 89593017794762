// Generated by Framer (38f2e7f)

import { addFonts, cx, CycleVariantState, Link, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {Q58XOx6Qe: {hover: true}};

const serializationHash = "framer-0QDH8"

const variantClassNames = {Q58XOx6Qe: "framer-v-gr44m0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Q58XOx6Qe", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"x.com/jwee_bs"} openInNewTab><motion.a {...restProps} {...gestureHandlers} aria-label={"X/Twitter"} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-gr44m0", className, classNames)} framer-k7z9f2`} data-framer-name={"x"} layoutDependency={layoutDependency} layoutId={"Q58XOx6Qe"} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"Q58XOx6Qe-hover": {opacity: 0.5}}} {...addPropertyOverrides({"Q58XOx6Qe-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><SVG className={"framer-dadkna"} data-framer-name={"x"} fill={"black"} intrinsicHeight={24} intrinsicWidth={24} layoutDependency={layoutDependency} layoutId={"A6YKazpSn"} svg={"<svg width=\"24\" height=\"24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M18.326 1.904H21.7l-7.37 8.423L23 21.79h-6.789l-5.317-6.952L4.81 21.79H1.434l7.883-9.01L1 1.904h6.961l4.806 6.354 5.56-6.354ZM17.142 19.77h1.87L6.945 3.817H4.94L17.142 19.77Z\" fill=\"#fff\"/></svg>"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0QDH8.framer-k7z9f2, .framer-0QDH8 .framer-k7z9f2 { display: block; }", ".framer-0QDH8.framer-gr44m0 { cursor: pointer; height: 24px; overflow: hidden; position: relative; text-decoration: none; width: 24px; }", ".framer-0QDH8 .framer-dadkna { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 24px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ela3l6ebU":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerSMg72mUoF: React.ComponentType<Props> = withCSS(Component, css, "framer-0QDH8") as typeof Component;
export default FramerSMg72mUoF;

FramerSMg72mUoF.displayName = "x";

FramerSMg72mUoF.defaultProps = {height: 24, width: 24};

addFonts(FramerSMg72mUoF, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})